
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { PagedResultOfProductsViewModel } from "@/api-client";
import { handleQueries } from "@/utils/handleQueries"
import { formatDate } from "@/utils/formatDate";
import { productsGet } from "@/api/products";

@Component({
  name: "ProductManagement",
  components: {
    DialogBox,
    Pagination,
  },
  filters: {
    statusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        false: 'danger',
        true: 'success',
        null: 'success'
      }
      return statusMap[status]
    }
  }
})
export default class extends Vue {
  tableData: PagedResultOfProductsViewModel = {
    totalCount: 0,
    pageCount: 0,
    pageSize: 0,
    pageNumber: 0,
    data: [],
  };
  pageNumber = 1;
  pageSize = 20;

  get params() {
    return {
      pageNumber: this.pageNumber.toString(),
      pageSize: this.pageSize.toString(),
    }
  }

  async created() {
    let toQuery = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }
    this.setQueryWatch(toQuery)

    this.loadInitialData();
  }

  setQueryWatch(fields: any) {
    let queries = this.$route.query;

    for (const field in fields) {
      if (queries[field]) {
        (this as any)[field] = queries[field];
      }

      this.$watch(field, (val) => {
          handleQueries(field, val)
      })
    }
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  handlePageNumberClick(page: any) {
    this.pageNumber = page;
    this.loadInitialData();
  }

  handlePageSizeChange(size: number) {
    this.pageNumber = 1;
    this.pageSize = size;
    this.loadInitialData()
  }

  async loadInitialData() {
    await productsGet(this.pageNumber, this.pageSize)
    .then(async (res) => {
      this.tableData = res;
    })
  }
}
